import { Editor } from "@tinymce/tinymce-react"
import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import gql from "graphql-tag"
import gsap from "gsap"
import $ from "jquery"
import Cookies from "js-cookie"
import React, { useEffect, useRef, useState } from "react"
import { Mutation } from "react-apollo"
import CookieConsent from "react-cookie-consent"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"

import Headroom from "react-headroom"
import Swiper, {
  Autoplay, EffectCoverflow, EffectFade, EffectFlip, Navigation,
  Pagination
} from "swiper"
import Logo from "../logo.inline.svg"
Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
])
const tl = gsap.timeline()

const Layout = ({ isHomePage, jobsFooter, jobId, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  const CONTACT_MUTATION = gql`
    mutation CreateSubmissionMutation(
      $clientMutationId: String!
      $firstName: String!
      $lastName: String!
      $email: String!
      $telephone: String!
      $message: String!
    ) {
      createSubmission(
        input: {
          clientMutationId: $clientMutationId
          firstName: $firstName
          lastName: $lastName
          email: $email
          telephone: $telephone
          message: $message
        }
      ) {
        success
        data
      }
    }
  `
  const JOB_MUTATION = gql`
    mutation CreateJobSubmissionMutation(
      $clientMutationId: String!
      $job: String!
      $firstName: String!
      $lastName: String!
      $email: String!
      $telephone: String!
      $cv: String!
      $message: String!
    ) {
      createJobSubmission(
        input: {
          clientMutationId: $clientMutationId
          job: $job
          firstName: $firstName
          lastName: $lastName
          email: $email
          telephone: $telephone
          cv: $cv
          message: $message
        }
      ) {
        success
        data
      }
    }
  `
  useEffect(() => {
    // Your code here
    // const swiper = new Swiper(".header-container", {
    //   loop: true,
    //   observer: true,
    //   observeParents: true,

    //   //simulateTouch: false,
    //   autoplay: {
    //     delay: 10000,
    //     waitForTransition: true,
    //   },
    //   preloadImages: true,
    //   centeredSlidesBounds: true,
    //   effect: "fade",
    //   fadeEffect: {
    //     crossFade: true,
    //   },
    //   speed: 1000,
    //   // Enable lazy loading
    //   lazy: true,
    // })

    // const swiperTest = new Swiper(".testimonial-container", {
    //   loop: true,
    //   autoplay: false,
    //   observer: true,
    //   observeParents: true,
    //   simulateTouch: false,
    //   centeredSlidesBounds: false,
    //   preloadImages: false,
    //   // Enable lazy loading
    //   lazy: false,
    //   pagination: {
    //     el: ".testimonial-pagination",
    //     type: "bullets",
    //     clickable: true,
    //   },
    // })

    // const swiperSlide = new Swiper(".business_advice__slides", {
    //   loop: true,
    //   observer: true,
    //   observeParents: true,
    //   simulateTouch: false,
    //   centeredSlidesBounds: true,
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   pagination: false,
    //   preloadImages: false,
    //   // Enable lazy loading
    //   lazy: true,
    // })

    // const swiperSlide2 = new Swiper(".blog_posts__container", {
    //   slidesPerView: 1,
    //   spaceBetween: 0,
    //   simulateTouch: true,
    //   centeredSlidesBounds: true,
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   preloadImages: true,
    //   // Enable lazy loading
    //   lazy: true,
    //   // when window width is >= 660px
    //   breakpoints: {
    //     660: {
    //       slidesPerView: 3,
    //       spaceBetween: 50,
    //     },
    //   },
    // })

    ReactGA.initialize("UA-7333804-1")
    ReactGA.pageview(window.location.pathname + window.location.search)

    const ScrollReveal = require("scrollreveal").default

    if (typeof document !== `undefined`) {
      // Changing the defaults

      ScrollReveal().reveal(
        "footer .container > div, " +
          ".meet_team__member," +
          ".services__card," +
          ".job_listings__card," +
          ".blog_posts__post," +
          "section:not(.header_section) .container-fluid > div, " +
          "section:not(.header_section) .container > div, section:not(.header) .container > form ",
        {
          reset: false,
          delay: 100,
          viewFactor: 0.1,
        }
      )
    }
    if (typeof window !== `undefined`) {
      import("materialize-css").then(M => {
        M.AutoInit()
      })

      tl.from(
        ".header_section__content h1",
        { opacity: 0, duration: 0.5, ease: "power3.out", y: +10 },
        0.9
      )
      tl.from(".header_section__content_sub p", {
        opacity: 0,
        duration: 0.2,
        ease: "power3.out",
        x: -10,
      })
      tl.from(".header_section__content_sub div", {
        opacity: 0,
        duration: 0.3,
        ease: "power3.out",
        x: -10,
      })
      tl.from(".header_section__content_btn", {
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        x: -10,
      })
    }

    $(".tcon.tcon-menu--xcross").on("click", function () {
      if ($("body").hasClass("sal-disabled")) {
        $(this).toggleClass("tcon-transform")
      }

      if ($(this).hasClass("tcon-transform")) {
        $("body").addClass("mobile_menu__open")
        $(".mobile_nav").addClass("menu_active").show().slideDown("slow")
        $("body").css({
          "overflow-y": "hidden",
        })
      } else {
        $("body").removeClass("mobile_menu__open")
        $(".mobile_nav").slideUp()
        $("body").css({
          "overflow-y": "unset",
        })
      }
      return false
    })

    $("#mob-menu li > a").on("click", function () {
      $(".tcon.tcon-menu--xcross").trigger("click")
    })
  }, [])

  const [cookieText, setCookieText] = useState(
    "This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish"
  )

  const [siteNav, setSiteNav] = useState([
    { name: "Services", uri: "/services" },
    { name: "Jobs", uri: "/jobs" },
    { name: "About", uri: "/about" },
    { name: "Blog", uri: "/blog" },
    { name: "Contact", uri: "#footerContact" },
  ])

  const [firstNameValue, setFirstNameValue] = useState("")
  const [lastNameValue, setLastNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [telephoneValue, setTelephoneValue] = useState("")
  const [messageValue, setMessageValue] = useState("")

  //jobs
  const [jobFirstNameValue, setJobFirstNameValue] = useState("")
  const [jobLastNameValue, setJobLastNameValue] = useState("")
  const [jobEmailValue, setJobEmailValue] = useState("")
  const [jobTelephoneValue, setJobTelephoneValue] = useState("")
  const [jobMessageValue, setJobMessageValue] = useState("")
  const cvUpload = useRef(null)
  const [cv, setCv] = useState("")
  const [cvFileType, setCvFileType] = useState("")

  const previewImage = () => {
    let oFReader = new FileReader()
    oFReader.readAsDataURL(cvUpload.current.files[0])
    let fileObject = cvUpload.current.files[0]
    oFReader.onload = function (oFREvent) {
      let newObject = {
        lastModified: fileObject.lastModified,
        lastModifiedDate: fileObject.lastModifiedDate,
        name: fileObject.name,
        size: fileObject.size,
        type: fileObject.type,
      }
      setCv(oFREvent.target.result)
      setCvFileType(fileObject.type)
    }
  }

  const [activeMenuMob, setActiveMenuMob] = useState(false)
  const [mobMenuClass, setMobMenuClass] = useState(false)

  const editorRef = useRef(null)
  const apiKey = "l9sq0cupjfgo0bzrhwii30wl4nw5ztxegaa04we5w1w0xi3x"

  const handleEditorChange = e => {
    setMessageValue(e.target.getContent())
  }

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <Helmet>
        <meta
          name="google-site-verification"
          content="U_NGarvI0WMcR6fbtGCW9vRcojiWVf3nTHCOCaZWxYE"
        />
      </Helmet>
      <Headroom pinStart={108}>
        <header>
          <div className="container">
            <div className="header">
              <div className="site_logo">
                <a href="/">
                  <Logo className="site-logo" />
                </a>
                <div className="header__number">
                  <a href="tel:01273823571">01273 823571</a>
                </div>
              </div>
              <div className="site_mobile">
                <a href="tel:01273823571">
                  <img
                    src="/assets/images/icons/moibile_phone.svg"
                    alt="Phone"
                  />
                </a>
              </div>
              <div className="site_nav">
                <nav>
                  <ul>
                    {siteNav.map(navItem => {
                      return (
                        <li key={navItem.uri}>
                          <Link to={navItem.uri}>{navItem.name}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
              <div className="mobile_buttons">
                <div className="mobile_nav_btn">
                  <button
                    type="button"
                    className={mobMenuClass ? mobMenuClass : undefined}
                    onClick={e => {
                      setActiveMenuMob(activeMenuMob ? false : true)
                      activeMenuMob
                        ? setMobMenuClass("tcon tcon-menu--xcross")
                        : setMobMenuClass(
                            "tcon tcon-menu--xcross tcon-transform"
                          )
                    }}
                    style={{
                      background: "transparent",
                      boxShadow: "none !important",
                      border: 0,
                    }}
                  >
                    <span
                      className="tcon-menu__lines"
                      aria-hidden="true"
                    ></span>{" "}
                    <span className="tcon-visuallyhidden">toggle menu</span>
                  </button>
                </div>
                {activeMenuMob && (
                  <Helmet
                    bodyAttributes={{
                      class: "mobile_menu__open",
                    }}
                  />
                )}
                <ul id="mob-menu" className="header__nav">
                  {siteNav.map(navItem => {
                    return (
                      <li key={navItem.uri}>
                        <Link to={navItem.uri}>{navItem.name}</Link>
                      </li>
                    )
                  })}

                  <li>
                    <div className="container">
                      <div className="footer_info__contact">
                        <div className="footer_info__contact_number">
                          <a href="tel:+441273 823571">+44 (0) 1273 823571</a>
                        </div>
                        <div className="footer_info__contact_email">
                          <a href="mailto:info@aestheticprofessionals.co.uk">
                            info@aestheticprofessionals.co.uk
                          </a>
                        </div>
                        <div className="footer_info__contact_socials">
                          <a
                            href="http://www.facebook.com/ARCAestheticProfessionals"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a
                            href="https://www.instagram.com/arcrec/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a
                            href="https://twitter.com/ARCAesthetics"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a
                            href="http://www.linkedin.com/company/2064779?trk=tyah"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
      <main>{children}</main>
      {!jobsFooter && (
        <footer className="footer" id="footerContact">
          <div className="container">
            <div className="footer_contact">
              <div className="footer_contact__icon">
                <img src="/assets/images/icons/email.svg" alt="email" />
              </div>
              <div className="footer_contact__box">
                <div className="footer_contact__box_title">Contact Us</div>
                <div className="footer_contact__box_desc">
                  Send us a message about your hiring needs or job search.
                </div>
                <div className="footer_contact__box_form">
                  <Mutation mutation={CONTACT_MUTATION}>
                    {(createSubmission, { loading, error, data }) => (
                      <React.Fragment>
                        <form>
                          <input
                            type="text"
                            placeholder="FIRST NAME"
                            id="first_name"
                            className="validate"
                            value={firstNameValue}
                            onChange={event => {
                              setFirstNameValue(event.target.value)
                            }}
                          />
                          <input
                            type="text"
                            placeholder="LAST NAME"
                            id="last_name"
                            className="validate"
                            value={lastNameValue}
                            onChange={event => {
                              setLastNameValue(event.target.value)
                            }}
                          />
                          <input
                            type="email"
                            placeholder="EMAIL"
                            id="email"
                            className="validate"
                            value={emailValue}
                            onChange={event => {
                              setEmailValue(event.target.value)
                            }}
                          />
                          <input
                            type="text"
                            placeholder="TELEPHONE"
                            id="telephone"
                            className="validate"
                            value={telephoneValue}
                            onChange={event => {
                              setTelephoneValue(event.target.value)
                            }}
                          />
                          <Editor
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            apiKey={apiKey}
                            require={true}
                            init={{
                              selector: "textarea",
                              body_class: "textarea_message",
                              placeholder: "COMMENTS",
                              menubar: false,
                              toolbar: false,
                              plugins: ["quickbars"],
                              quickbars_insert_toolbar: "",
                              quickbars_selection_toolbar:
                                "italic underline bold",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              content_css: "/tiny.css", // resolved to http://domain.mine/mysite/mycontent.css
                            }}
                            onChange={event => {
                              handleEditorChange(event)
                            }}
                          />

                          <button
                            type="submit"
                            className="form_submit waves-effect waves-dark btn"
                            onClick={async event => {
                              event.preventDefault()
                              if (
                                firstNameValue &&
                                lastNameValue &&
                                emailValue &&
                                telephoneValue &&
                                editorRef.current.getContent()
                              ) {
                                if (
                                  emailValue.includes("@") &&
                                  emailValue.includes(".")
                                ) {
                                  createSubmission({
                                    variables: {
                                      clientMutationId: JSON.stringify(
                                        Date.now()
                                      ),
                                      firstName: firstNameValue,
                                      lastName: lastNameValue,
                                      email: emailValue,
                                      telephone: telephoneValue,
                                      message: editorRef.current.getContent(),
                                    },
                                  })
                                } else {
                                  alert("Please ensure email address is valid")
                                }
                              } else {
                                alert("Please ensure all fields are filled in")
                              }
                            }}
                          >
                            SEND
                          </button>
                        </form>

                        <div
                          className="form_errors"
                          style={{
                            margin: "30px 0px 10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {loading && (
                            <p
                              style={{
                                margin: 10,
                                minWidth: "100%",
                                textAlign: "center",
                                fontSize: "1.3em",
                                fontWeight: 500,
                              }}
                            >
                              Sending...
                            </p>
                          )}
                          {error && (
                            <div
                              style={{
                                padding: "20px",
                                minWidth: "100%",
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                backgroundColor: "#d06464",
                                color: "#eae9e1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p>
                                An unknown error has occured, please try again
                                later...
                              </p>
                            </div>
                          )}
                          {data && (
                            <div
                              style={{
                                padding: "20px",
                                minWidth: "100%",
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                backgroundColor: "#98c2a4",
                                color: "#ffffff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ margin: 0 }}>
                                Thank you for getting in touch!
                              </div>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_info">
            <div className="home_footer_path">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 1920 179.963"
              >
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M0,5318.732H1920v-155.78s-566.657-59.133-954.274,7.536S0,5162.953,0,5162.953Z"
                  transform="translate(1920 5318.732) rotate(180)"
                  fill="#8c8299"
                />
              </svg>
            </div>
            <div className="container">
              <div className="footer_info__logo">
                <div className="footer_info__logo_img">
                  <Logo className="footer-logo" />
                </div>
                <div className="footer_info__logo_text">
                  <p>The Expert Recruiters in Medical Aesthetics</p>
                </div>
              </div>
              <div className="footer_info__contact">
                <div className="footer_info__contact_number">
                  <a href="tel:+441273 823571">+44 (0) 1273 823571</a>
                </div>
                <div className="footer_info__contact_email">
                  <a href="mailto:info@aestheticprofessionals.co.uk">
                    info@aestheticprofessionals.co.uk
                  </a>
                </div>
                <div className="footer_info__contact_socials">
                  <a
                    href="http://www.facebook.com/ARCAestheticProfessionals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/arcrec/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://twitter.com/ARCAesthetics" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="http://www.linkedin.com/company/2064779?trk=tyah"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="footer_info__copy">
                <p>
                  Company no: 06711267 <br />
                  VAT no: GB945226322 <br />
                  Registered address: Lynnem House, 1 Victoria Way, Burgess
                  Hill, West Sussex, RH15 9NF
                </p>
              </div>
            </div>
          </div>
          <div className="footer_links">
            <div className="container">
              <div className="footer_links__holder">
                <a href="/terms/" className="footer_links__link">
                  Terms &amp; conditions
                </a>
                <a href="/privacy-policy/" className="footer_links__link">
                  Privacy Policy
                </a>
                <div href="" className="footer_links__link">
                  © 2021 ARC Ltd
                </div>
              </div>
            </div>
          </div>
          {!Cookies.get("ArcCookie") && (
            <CookieConsent
              location="bottom"
              debug={false}
              buttonText="Accept"
              cookieName="ArcCookie"
              style={{
                background: "#353343",
                color: "#F4F4F0",
                fontSize: "1.2rem",
              }}
              buttonStyle={{
                background: "#8c8299",
                color: "#F4F4F0",
                fontSize: "1.2rem",
                padding: "5px 15px",
              }}
              expires={30}
            >
              {cookieText}
              <Link to="/privacy-policy/">
                <button
                  style={{
                    fontSize: "1.2rem",
                    float: "right",
                    position: "absolute",
                    top: "-5px",
                    right: "-15px",
                    padding: "5px 15px",
                    backgroundColor: "#edebe4",
                    color: "#4d4961",
                    border: "none",
                  }}
                >
                  Read more
                </button>
              </Link>
            </CookieConsent>
          )}
        </footer>
      )}

      {jobsFooter && (
        <footer className="footer footer--jobs" id="footerContact">
          <div className="container">
            <div className="footer_contact">
              <div className="footer_contact__box">
                <div className="footer_contact__box_title">Apply Now</div>

                <div className="footer_contact__box_form">
                  <Mutation mutation={JOB_MUTATION}>
                    {(createJobSubmission, { loading, error, data }) => (
                      <React.Fragment>
                        <form className="form">
                          <input type="hidden" id="job_id" value={jobId} />
                          <input
                            type="text"
                            placeholder="FIRST NAME"
                            id="first_name"
                            className="validate"
                            value={jobFirstNameValue}
                            onChange={event => {
                              setJobFirstNameValue(event.target.value)
                            }}
                          />
                          <input
                            type="text"
                            placeholder="LAST NAME"
                            id="last_name"
                            className="validate"
                            value={jobLastNameValue}
                            onChange={event => {
                              setJobLastNameValue(event.target.value)
                            }}
                          />
                          <input
                            type="email"
                            placeholder="EMAIL"
                            d="email"
                            className="validate"
                            value={jobEmailValue}
                            onChange={event => {
                              setJobEmailValue(event.target.value)
                            }}
                          />
                          <input
                            type="text"
                            placeholder="TELEPHONE"
                            id="first_name"
                            className="validate"
                            value={jobTelephoneValue}
                            onChange={event => {
                              setJobTelephoneValue(event.target.value)
                            }}
                          />
                          <div className="file-field input-field">
                            <div className="btn putty">
                              <input
                                id="cvUpload"
                                ref={cvUpload}
                                onChange={previewImage}
                                type="file"
                                accept=".doc,.docx,.pdf"
                              />
                              <span>UPLOAD FILE</span>
                            </div>
                            <div className="file-path-wrapper">
                              <input
                                className="file-path validate"
                                type="text"
                                placeholder="ATTACHMENT"
                              />
                            </div>
                          </div>
                          {(() => {
                            if (cv && cvFileType === "pdf") {
                              return (
                                <div style={{ marginBottom: 30 }}>
                                  <object aria-labelledby={cv} data={cv}></object>
                                </div>
                              )
                            }
                          })()}

                          <Editor
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            apiKey={apiKey}
                            require={true}
                            init={{
                              selector: "textarea",
                              body_class: "textarea_message",
                              placeholder: "COVER LETTER",
                              menubar: false,
                              toolbar: false,
                              plugins: ["quickbars"],
                              quickbars_insert_toolbar: "",
                              quickbars_selection_toolbar:
                                "italic underline bold",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              content_css: "/tiny.css", // resolved to http://domain.mine/mysite/mycontent.css
                            }}
                            onChange={event => {
                              handleEditorChange(event)
                            }}
                          />
                          <button
                            className="form_submit waves-effect waves-dark btn"
                            onClick={async event => {
                              event.preventDefault()
                              if (
                                jobFirstNameValue &&
                                jobLastNameValue &&
                                jobEmailValue &&
                                jobTelephoneValue &&
                                editorRef.current.getContent()
                              ) {
                                if (
                                  jobEmailValue.includes("@") &&
                                  jobEmailValue.includes(".")
                                ) {
                                  createJobSubmission({
                                    variables: {
                                      clientMutationId: JSON.stringify(
                                        Date.now()
                                      ),
                                      job: jobId,
                                      firstName: jobFirstNameValue,
                                      lastName: jobLastNameValue,
                                      email: jobEmailValue,
                                      telephone: jobTelephoneValue,
                                      cv: JSON.stringify(cv),
                                      message: editorRef.current.getContent(),
                                    },
                                  })
                                } else {
                                  alert("Please ensure email address is valid")
                                }
                              } else {
                                alert("Please ensure all fields are filled in")
                              }
                            }}
                          >
                            SEND
                          </button>
                        </form>

                        <div
                          className="form_errors"
                          style={{
                            margin: "30px 0px 10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {loading && (
                            <p
                              style={{
                                margin: 10,
                                minWidth: "100%",
                                textAlign: "center",
                                fontSize: "1.3em",
                                fontWeight: 500,
                              }}
                            >
                              Sending...
                            </p>
                          )}
                          {error && (
                            <div
                              style={{
                                padding: "20px",
                                minWidth: "100%",
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                backgroundColor: "#d06464",
                                color: "#eae9e1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p>
                                An unknown error has occured, please try again
                                later...
                              </p>
                            </div>
                          )}
                          {data && (
                            <div
                              style={{
                                padding: "20px",
                                minWidth: "100%",
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                backgroundColor: "#98c2a4",
                                color: "#ffffff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ margin: 0 }}>
                                Thank you! Your message has been sent.
                              </div>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_info">
            <div className="home_footer_path">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 1920 179.963"
              >
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M0,5318.732H1920v-155.78s-566.657-59.133-954.274,7.536S0,5162.953,0,5162.953Z"
                  transform="translate(1920 5318.732) rotate(180)"
                  fill="#8c8299"
                />
              </svg>
            </div>
            <div className="container">
              <div className="footer_info__logo">
                <div className="footer_info__logo_img">
                  <img src="/assets/images/arc-logo-light.svg" alt="Arc logo" />
                </div>
                <div className="footer_info__logo_text">
                  <p>The Expert Recruiters in Medical Aesthetics</p>
                </div>
              </div>
              <div className="footer_info__contact">
                <div className="footer_info__contact_number">
                  <a href="tel:+441273 823571" target="_blank" rel="noopener noreferrer">
                    +44 (0) 1273 823571
                  </a>
                </div>
                <div className="footer_info__contact_email">
                  <a
                    href="tel:info@aestheticprofessionals.co.uk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@aestheticprofessionals.co.uk
                  </a>
                </div>
                <div className="footer_info__contact_socials">
                  <a
                    href="http://www.facebook.com/ARCAestheticProfessionals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/arcrec/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://twitter.com/ARCAesthetics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="http://www.linkedin.com/company/2064779?trk=tyah"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="footer_info__copy">
                <p>
                  Company no: 06711267 <br />
                  VAT no: GB945226322 <br />
                  Registered address: Lynnem House, 1 Victoria Way, Burgess
                  Hill, West Sussex, RH15 9NF
                </p>
              </div>
            </div>
          </div>
          <div className="footer_links">
            <div className="container">
              <div className="footer_links__holder">
                <a href="/terms/" className="footer_links__link">
                  Terms &amp; conditions
                </a>
                <a href="/privacy-policy/" className="footer_links__link">
                  Privacy Policy
                </a>
                <div className="footer_links__link">© 2021 ARC Ltd</div>
              </div>
            </div>
          </div>
          {!Cookies.get("ArcCookie") && (
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="ArcCookie"
              style={{
                background: "#353343",
                color: "#F4F4F0",
                fontSize: "1.2rem",
              }}
              buttonStyle={{
                background: "#8c8299",
                color: "#F4F4F0",
                fontSize: "1.2rem",
              }}
              expires={150}
            >
              {cookieText}
              <Link to="/privacy-policy/">
                <button
                  style={{
                    fontSize: "1.2rem",
                    float: "right",
                    position: "absolute",
                    top: "-5px",
                    right: "-15px",
                    padding: "5px 15px",
                    backgroundColor: "#edebe4",
                    color: "#4d4961",
                    border: "none",
                  }}
                >
                  Read more
                </button>
              </Link>
            </CookieConsent>
          )}
        </footer>
      )}
      <Helmet>
        <script src={withPrefix("app.js")} async />
        <script src="//js.hsforms.net/forms/shell.js" async></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-7333804-1"
        ></script>
      </Helmet>
    </div>
  )
}

export default Layout
